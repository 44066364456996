import React, { Component } from "react";
import './styles/frontabout.css';
import $ from 'jquery';


class FrontAbout extends Component {
    componentDidMount() {

    }
render(){
    return(
    <div className="frontAboutContainer">
        <div className="aboutWrap">
        <span className="miniHead">About us</span>  
        <p className="mainP">Well-designed websites, 
        delivered on time, and custom-made 
        specifically for your company</p>
        <span class="littleP">We’re a design & development agency that builds high quality brands, 
        websites and digital experiences that will take your business to the next level.</span>
        </div>
        <div className="svgDiv">
            <img id="aboutSvg" src="./assets/images/adjprocess.svg"></img>
        </div>
        
        </div>
       
    )
}
}

export default FrontAbout;