import React, { Component } from "react";
import {Animated} from "react-animated-css";

import './styles/work.css';
import $ from 'jquery';


class Work extends Component {
    componentDidMount() {
       
    }
render(){
    return(
    <div className="workContainer">
       <h2 className="bigHead">RECENT WORK</h2>
        <div className="carouselWrap">
        <div className="carouselImgDiv">
            <img id="hayes" className="carouselImg" src="./assets/images/hbcscreens.png"></img>
            </div>
            <div className="carouselTxt">
            <span className="carouselTitle">Hayes Beer Distributing Co.</span>
            <p className="carouselDesc">By utilizing best-practices and proven solutions, we craft authentic, memorable and consistent brand experiences across all platforms from digital and print marketing materials to user interfaces, web design and mobile applications.

            </p>
            </div>
            
           
            
        </div>
        </div>
       
    )
}
}

export default Work;