import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import './styles/About.css';
import Email from '../components/Home/Email.js'
import Footer from '../components/Home/Footer.js'
import $ from 'jquery';
import Reviews from '../components/About/Reviews';

class About extends Component {
    componentDidMount() {
      var something = (function() {
        var executed = false;
        return function() {
            if (!executed) {
                executed = true;
                // do something
                (window.location.reload(false));
            }
        };
    })();
      // function windowReload(){
      //   (window.location.reload(false));
      //  }
    
      // windowReload();


      window.scrollTo(0, 0)
      $(document).scroll(function() {
        var y = $(this).scrollTop();
        console.log(y)
        
    
          if (y >= 80 ) {
            $('.profiles').fadeIn();
            $('#dev').css("margin-right", "18%");
            $('#flip').css("margin-left", "18%");
            
          }
          else{
            $('.profiles').fadeOut();
            $('#dev').css("margin-right", "0px");
            $('#flip').css("margin-left", "0px");
          }
          if (y >= 190) {
            $('.skillset').fadeIn();
          }
          else{
            
            $('.skillset').fadeOut();
          }
  
          if (y >= 250) {
            $('.years').fadeIn();
          
          }
          else{
            $('.years').fadeOut();
          }
  
          if (y >= 360) {
            
          $('.revSlideshow').css("opacity", "1");
          $('.brandsContainer').css("opacity", "1");
          }
          
          else{
            $('.revSlideshow').css("opacity", "0");
            $('.brandsContainer').css("opacity", "0");
          }
  
          if (y >= 800) {
            $('.buffer').fadeOut();
          $('.aboutEmailWrap').css("opacity", "1");
          $('.adjPicDiv').css("z-index", "-1");
          }
          else{
            $('.buffer').fadeIn();
            $('.aboutEmailWrap').css("opacity", "0");
            $('.adjPicDiv').css("z-index", "0");
          }
        
       
       });

    }

render(){
  
    return(
     

<div className="aboutContainer">  
        
    <h1 id="aboutTitle">
      Meet the team
    </h1>
    <div className="badgeEdge"></div>
   
      <div class="adjPicDiv">
  
      <img id="dev" class="adjPic" src="./assets/images/devinlines.png" alt="adjectiveabout"/>
      <img id="joey" class="adjPic" src="./assets/images/joeylines.png" alt="adjectiveabout"/>
      <img id="flip" class="adjPic" src="./assets/images/andrewlines.png" alt="adjectiveabout"/>
    </div>
    
 
    <div className="profiles">

      <div className="nameTags">
          <div className="experience">
          <h2 className="name">Devin Garrett</h2>
          <h3 className="skillset short">HTML</h3>
          <h3 className="skillset short">CSS</h3>
          <h3 className="skillset short">JAVASCRIPT</h3>
          <h3 className="years">4 Years of Sales</h3>
        </div>
        
        <div className="experience">
          <h2 className="name">Joey Lansangan</h2>
          <h3 className="skillset short">REACT.JS</h3>
          <h3 className="skillset short">PHOTOSHOP</h3>
          <h3 className="skillset short">ILLUSTRATOR</h3>
          <h3 className="years">4 Years of Engineering</h3>
        </div>

        <div className="experience">
          <h2 className="name">Andrew Doss</h2>
          <h3 className="skillset short">ENGAGEMENT</h3>
          <h3 className="skillset short">ANALYTICS</h3>
          <h3 className="skillset short">RESEARCH</h3>
          <h3 className="years">6 Years of Engineering</h3>
        </div>
  
      </div>

      <Reviews />
  
      <div className="brandsContainer">
        
        <h2 className="bigHead">brands
        <hr className="bigHeadLine"></hr>
        </h2>
       
        <div className="brandsCardsWrap">
          <div className="brandLogosDiv">
            <div>
              <img className="logoEmboss" src="./assets/images/icons/hbemboss.png"></img>
              <img className="logoEmboss" src="./assets/images/icons/upbitemboss.png"></img>
            </div>
            <div>
              <img className="logoEmboss" src="./assets/images/icons/thisdroppedemboss.png"></img>
              <img className="logoEmboss" src="./assets/images/icons/taqueriaemboss.png"></img>
            </div>
            
          </div>
        
      
        </div>
     

        </div>
        <div className="aboutEmailWrap">
        <div className="mailHeader">
                  <h2 className="talk">Ready to Talk?</h2>
                  <div className="mailBtns">
                      <a id="contact" href="mailto: adjective.mediaco@gmail.com">
                      <button className="aboutEmailBtn">Start a project</button>
                      </a>
                      
                  </div>
          </div>
      </div>

    </div>

    



       

  {/* <Email /> */}
    <div className="buffer">

    </div>


   
</div>

    )
}
}
export default About;