import React, { Component } from "react";
import {Animated} from "react-animated-css";

import './styles/process.css';
import $ from 'jquery';


class Process extends Component {
    componentDidMount() {

    }
render(){
    return(
    <div className="processContainer"> 
        
        <h2 className="bigHead">our process
        <hr className="bigHeadLine"></hr>
        </h2>
        
        <div className="processCardsWrap">

        
        <div className="processCardsDiv">
            <div className="processCard">
                <div className="processCard1">
                <h2 className="phase"> phase 1</h2>
                <h2 className="phaseHead">Research</h2>
                </div>
                <p className="phaseTxt">Understanding what you are building, 
                why it is being built, and how 
                we are going to leverage your brand experience 
                with our expert UX / UI design.  
                </p>
            </div>
         

        </div>
       
        <div className="processCardsDiv">
            <div className="processCard">
            <div className="processCard2">
                <h2 className="phase"> phase 2</h2>
                <h2 className="phaseHead">Design</h2>
                </div>
                <p className="phaseTxt">Putting the proverbial ‘rubber to the road’ -
                This phase is where we create high-fidelity mockups 
                done completely custom in Adobe Photoshop & Illustrator.
                </p>
            </div>
          
        </div>
        <div className="processCardsDiv">
            <div className="processCard">
            <div className="processCard3">
                <h2 className="phase"> phase 3</h2>
                <h2 className="phaseHead">Develop</h2>
                </div>
                <p className="phaseTxt">The conversion of approved digital designs 
                into front end markup and back end features. All capabilities tested for successful deployment.
                </p>
            </div>
     
        </div>
        </div>
     

        </div>
       
    )
}
}

export default Process;