import React, { Component } from "react";
import './styles/badges.css';
import $ from 'jquery';


class Badges extends Component {
    componentDidMount() {
        $(document).scroll(function() {
            var y = $(this).scrollTop();
            console.log(y)
            if (y > 200) {
                $("#badgeOne").css("margin-left", "0");
                $("#descOne").css("color", "white");
            } 
            if (y > 550) {
                $("#badgeTwo").css("margin-left", "0");
                $("#descTwo").css("color", "white");
            } 
            if (y > 700) {
                $("#badgeTri").css("margin-left", "0");
                $("#descTri").css("color", "white");
            } 
        })
    }
render(){
    return(
<div className="badgesContainer">
    <div className="badgesWrap">
        <div className="badgeItem">
            {/* <img className="badge" id="badgeOne" src="./assets/images/icons/stroke3.png" alt="<>"/> */}
            <h1 className="desc" id="descOne">We believe that <span className="badgeSpan" >Collaborating</span> with the right people is key to building beautiful products. </h1>
        </div>
        <div className="badgeItem">
            {/* <img className="badge" id="badgeTwo" src="./assets/images/icons/stroke4.png" alt="<>"/> */}
            <h1 className="desc" id="descTwo">We build by <span className="badgeSpan" >Understanding</span> the people and the community we share.</h1>
        </div>
        <div className="badgeItem" id="lastBadge">
            {/* <img className="badge" id="badgeTri" src="./assets/images/icons/stroke2.png" alt="<>"/> */}
            <h1 className="desc" id="descTri">We would love to chat about your Web needs! Continue scrolling to see our  <span className="badgeSpan" >Creative Web Media</span> portfolio.</h1>
        </div>
    </div>
        {/* <div className="badgesWrap">
        <div className="badgeItem">
                    
            <div className="badgeDiv" id="badgeOne">
                <img className="badge" src="./assets/images/icons/stroke3.png" alt="<>"/>
                </div>

                <div className="badgeDesc">
                <h1 className="desc" id="descOne">We believe that <span className="badgeSpan" >Building</span> things is fundamental to what makes us human. It’s why we do what we do.</h1>
                </div>

            </div>
        </div> */}
 

        </div>
       
    )
}
}

export default Badges;