
import React, { Component } from "react";
import { Link } from 'react-router-dom'
// import './styles/projects.css';
import $ from 'jquery';



class Projects extends Component {
    componentDidMount() {
        /* --------------------------------------------------
Slider Carousel
-------------------------------------------------- */

var $visibleSlide, getDataSlide, sliderInterval, getDataNextSlide, getDataPrevSlide, getDataNavDot, isAnimating = false;
var fadeDuration = 1000;
var pause = 5000;

//show first slide
$('.slides li:first-child').css('display', 'block');

//show first nav dot
$('.nav li:first-child').addClass('active-cd');

//find out what slide is visible and get its data attribute
function getSlideInfo() {
  $visibleSlide = $('.slides').find('li:visible');
  getDataSlide = $visibleSlide.data('n');
  getDataNextSlide = $visibleSlide.next().data('n');
  getDataPrevSlide = $visibleSlide.prev().data('n');
}

//show next slide
function showNextSlide() {
  getSlideInfo();

  if(isAnimating) return false;
  
  isAnimating = true;
  
  $('.nav li').removeClass('active-cd');

  if (getDataSlide < 3) {
    $visibleSlide.fadeOut(fadeDuration);
    $visibleSlide.next().fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li[data-cd=' + getDataNextSlide + ']').addClass('active-cd');
  } else {
    $visibleSlide.fadeOut(fadeDuration);
    $('.slides li:first-child').fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li:first-child').addClass('active-cd');
  }
} //end showNextSlide

function showPrevSlide() {
  getSlideInfo();

  if(isAnimating) return false;
  
  isAnimating = true;
  
  $('.nav li').removeClass('active-cd');

  if (getDataSlide > 1) {
    $visibleSlide.fadeOut(fadeDuration);
    $visibleSlide.prev().fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li[data-cd=' + getDataPrevSlide + ']').addClass('active-cd');
  } else {
    $visibleSlide.fadeOut(fadeDuration);
    $('.slides li:last-child').fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li:last-child').addClass('active-cd');
  }

} // end showPrevSlide

// controls
$('.next').on('click', showNextSlide);
$('.prev').on('click', showPrevSlide);

//autoplay
function startSlider() {
  sliderInterval = setInterval(showNextSlide, pause)
}
startSlider();
$('.slideshow').mouseenter(function() {
  clearInterval(sliderInterval);
});
$('.slideshow').mouseleave(startSlider);

//control dots clicks
$('.nav li').on('click', function() {
  getDataNavDot = $(this).data('cd');
  getSlideInfo();

  $('.nav li').removeClass('active-cd');
  $(this).addClass('active-cd');

  $visibleSlide.fadeOut(fadeDuration);
  $('.slides li[data-n=' + getDataNavDot + ']').fadeIn(fadeDuration);
}); //end dots click
          };
        

    
    
    render()
    {
        return(


            <div className="ga slideshow">
              <div className="bothArrows">
  <div className="prev arrow arrow-left"></div>
  <div className="next arrow arrow-right"></div>
  </div>
  <ul className="slides">
    <li data-n="1" className="carouselPiece">
      <a href="adjective.media"><img width="50%" height="50%" src="./assets/images/hbcscreens.png" alt="Nina Lu" /></a>
      <div className="carouselDesc">
<h2>Title</h2>
<p>View Chicago Outdoor Advertising, one of the fastest growing Outdoor companies in the country is looking for an outside Account Executive to join our local team. We are a local company that builds relationships and trust with our customers. We achieve that by being out in the market and meeting face to face with our clients and potential clients. Sales is not for everyone, but if it is for you then this is a great opportunity. olid compan</p>
      </div>
    </li>

    <li data-n="2" className="carouselPiece">
      <a href="adjective.media"><img width="50%" height="50%" src="./assets/images/icons/stroke4.png" alt="Lyndsy Fonseca" /></a>
      <div className="carouselDesc">
<h2>Title</h2>
<p>View Chicago Outdoor Advertising, one of the fastest growing Outdoor companies in the country is looking for an outside Account Executive to join our local team. We are a local company that builds relationships and trust with our customers. We achieve that by being out in the market and meeting face to face with our clients and potential clients. Sales is not for everyone, but if it is for you then this is a great opportunity. olid compan</p>
      </div>
    </li>

    <li data-n="3" className="carouselPiece">
      <a href="adjective.media"><img width="50%" height="50%" src="./assets/images/icons/stroke2.png" alt="Become the next model" /></a>
      <div className="carouselDesc">
<h2>Title</h2>
<p>View Chicago Outdoor Advertising, one of the fastest growing Outdoor companies in the country is looking for an outside Account Executive to join our local team. We are a local company that builds relationships and trust with our customers. We achieve that by being out in the market and meeting face to face with our clients and potential clients. Sales is not for everyone, but if it is for you then this is a great opportunity. olid compan</p>
      </div>
    </li>
  </ul>

  <ul className="nav">
    <li data-cd="1"></li>
    <li data-cd="2"></li>
    <li data-cd="3"></li>
  </ul>
</div>
    )
}
}









export default Projects;