
import React, { Component } from "react";
import { Link } from 'react-router-dom'
import './styles/carousel.css';
import $ from 'jquery';



class Carousel extends Component {
    componentDidMount() { 

      
        /* --------------------------------------------------
Slider Carousel
-------------------------------------------------- */

var $visibleSlide, getDataSlide, sliderInterval, getDataNextSlide, getDataPrevSlide, getDataNavDot, isAnimating = false;
var fadeDuration = 1000;
var pause = 5000;

//show first slide
$('.slides li:first-child').css('display', 'block');

//show first nav dot
$('.nav li:first-child').addClass('active-cd');

//find out what slide is visible and get its data attribute
function getSlideInfo() {
  $visibleSlide = $('.slides').find('li:visible');
  getDataSlide = $visibleSlide.data('n');
  getDataNextSlide = $visibleSlide.next().data('n');
  getDataPrevSlide = $visibleSlide.prev().data('n');
}

//show next slide
function showNextSlide() {
  getSlideInfo();

  if(isAnimating) return false;
  
  isAnimating = true;
  
  $('.nav li').removeClass('active-cd');

  if (getDataSlide < 4) {
    $visibleSlide.fadeOut(fadeDuration);
    $visibleSlide.next().fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li[data-cd=' + getDataNextSlide + ']').addClass('active-cd');
  } else {
    $visibleSlide.fadeOut(fadeDuration);
    $('.slides li:first-child').fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li:first-child').addClass('active-cd');
  }
} //end showNextSlide

function showPrevSlide() {
  getSlideInfo();

  if(isAnimating) return false;
  
  isAnimating = true;
  
  $('.nav li').removeClass('active-cd');

  if (getDataSlide > 1) {
    $visibleSlide.fadeOut(fadeDuration);
    $visibleSlide.prev().fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li[data-cd=' + getDataPrevSlide + ']').addClass('active-cd');
  } else {
    $visibleSlide.fadeOut(fadeDuration);
    $('.slides li:last-child').fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li:last-child').addClass('active-cd');
  }

} // end showPrevSlide

// controls
$('.next').on('click', showNextSlide);
$('.prev').on('click', showPrevSlide);

//autoplay
// function startSlider() {
//   sliderInterval = setInterval(showNextSlide, pause)
// }
// startSlider();
// $('.slideshow').mouseenter(function() {
//   clearInterval(sliderInterval);
// });
// $('.slideshow').mouseleave(startSlider);

// control dots clicks
// $('.nav li').on('click', function() {
//   getDataNavDot = $(this).data('cd');
//   getSlideInfo();

//   $('.nav li').removeClass('active-cd');
//   $(this).addClass('active-cd');

//   $visibleSlide.fadeOut(fadeDuration);
//   $('.slidesCar li[data-n=' + getDataNavDot + ']').fadeIn(fadeDuration);
// }); //end dots click

          };
        
    
    render()
    {
        return(


  <div className="ga slideshow">
    <div className="bothArrows">
      <div className="prev arrow arrow-left"></div>
      <div className="next arrow arrow-right"></div>
    </div>
 
    <ul className="slides">
      <li data-n="1" className="carouselPiece">
        <a className="workImg" ><img width="85%" height="80%" src="./assets/images/hbcscreens.png" alt="Hayes Beer Distributing" /></a>
        <div className="carouselDesc">

          <h2>Hayes Beer Distributing Co.</h2>
          <p>Hayes Beer Distributing Company is one of the largest beer distributing company in the state of Illinois. Providing service to more than 2,000 retail accounts between two locations, Hayes Beer Co. represents the entire portfolio of the Miller Brewing Company, as well as Heineken, Corona, Coors, Samuel Adam's and Mike's Hard Lemonade to name a few.</p>

      </div>
      </li>

      <li data-n="2" className="carouselPiece">
      <a className="workImg" ><img width="85%" height="80%" src="./assets/images/thisdroppedscreens.png" alt="thisDropped" /></a>
        <div className="carouselDesc">

          <h2>ThisDropped</h2>
          <p>ThisDropped is a music-oriented online magazine bringing you the latest hits, news, and podcasts. From the latest in R&B/HipHop, Pop, Rock, and Country, ThisDropped is your one-stop-shop for music.</p>

      </div>
      </li>

      <li data-n="3" className="carouselPiece">
      <a className="workImg" ><img width="85%" height="80%" src="./assets/images/upbitscreens.png" alt="Nina Lu" /></a>
        <div className="carouselDesc">

          <h2>Upbit Cryptocurrency App</h2>
          <p>Upbit is an online crypto trading and investment tool. The modern way to handle your cryptocurrency is now here. With UpBit’s innovation and technology, you can find out the best ways to manage your crypto investments and learn the latest news and trends.</p>
      </div>
      </li>
      <li data-n="4" className="carouselPiece">
      <a className="workImg" ><img width="85%" height="80%" src="./assets/images/tpscreens.png" alt="Nina Lu" /></a>
        <div className="carouselDesc">
          <h2>Taqueria Puebla</h2>
          <p>Taqueria Puebla is an authentic family-owned taco joint located in Chicago, Illinois. For years, the family has been serving customers with traditional Mexican cuisine. Famous for their corn tortillas and comfy atmosphere, you can’t go wrong eating at this neighborhood classic.</p>

      </div>
      </li>
    </ul>

    <ul className="nav">
      <li data-cd="1"></li>
      <li data-cd="2"></li>
      <li data-cd="3"></li>
      <li data-cd="4"></li>
    </ul>
</div>

    )
}
}









export default Carousel;