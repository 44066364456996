import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import './styles/Hbc.css';
import Email from '../components/Home/Email.js'
import Footer from '../components/Home/Footer.js'
import $ from 'jquery';

class Hbc extends Component {
    componentDidMount() {
      window.scrollTo(0, 0)

        function slider(){
            let $current= $('.slider img.active');
            if($current.length === 0){
              $('.slider img:first-child').addClass('active');
            }else{
              let $next = $current.removeClass('active').next();
              if($next.length === 0){
                $('.slider img:first-child').addClass('active');
              }else{ 
                $next.addClass('active');
              }
            }
          }
          setInterval(slider,3000);
      
          $(document).scroll(function() {
            var y = $(this).scrollTop();
            if (y > 200) {
              $('.topsidetext').animate({margin: '0 0 0 0'}, 2000);
            }
           });
           $(document).scroll(function() {
            var y = $(this).scrollTop();
            if (y > 300) {
              $('.topmidsidetext').animate({margin: '0 0 0 0'}, 2000);
            }
           });
           $(document).scroll(function() {
            var y = $(this).scrollTop();
            if (y > 420) {
              $('.botmidsidetext').animate({margin: '0 0 0 0'}, 2000);
            }
           });
           $(document).scroll(function() {
            var y = $(this).scrollTop();
            if (y > 550) {
              $('.botsidetext').animate({margin: '0 0 0 0'}, 2000);
            }
           });
    }

render(){
    return(
        <div> 
        
        <h1 id="hayesBeer">
           <img src="./assets/images/icons/hb-logo.png" width="100px" height="100px" alt="hayesbeer"/>
           Hayes Beer Distribution
       </h1>
       <div className="badgeEdge"></div>
   <div class="portFront">
               <div class="vidDiv">
                       <video width="100%" height="100%" autoPlay muted loop id="myVideo">
                               <source  src="./assets/vids/hbcvid.mov" autoplay muted loop type="video/mp4"/>
                           </video>
               </div>
   </div>
   <div className="workHeader">
   <h3 class="midTxtDiv">Hayes Beer Distributing Company is one of the largest beer distributing company in the state of Illinois. Providing service to more than 2,000 retail accounts between two locations, Hayes Beer Co. represents the entire portfolio of the Miller Brewing Company, as well as Heineken, Corona, Coors, Samuel Adam's and Mike's Hard Lemonade to name a few.  </h3>
       <br></br>
       <h3 class="midTxtDiv">In collabarotion with Luke J. Hayes (son of CEO of Hayes Beer Co.), we are tasked to recreate the archaic look of the existing company website. We introduced a fresh and modern look to the website that will attact more visitors as well as site functionalities for employees. </h3>
   </div>
   <div class="slider">
            <img class="slide" src="./assets/images/hayes1.png"/>
            <img class="slide" src="./assets/images/hayes2.png"/>
            <img class="slide" src="./assets/images/hayes3.png"/>
            <img class="slide" src="./assets/images/hayes4.png"/>
    </div>

    <h1 id="words">
    <Link id="wordLink" to="/portfolio">
    VIEW OUR PORTFOLIO
    </Link>

    </h1>


    

    <Email/>
</div>

    )
}
}
export default Hbc;