import React, { Component } from "react";
import { Link } from 'react-router-dom'

import './assets/css/style.css';
import './assets/css/mediaquerynav.css';

import $ from 'jquery';


class Navbar extends Component {
    componentDidMount() {
        // $("#homeId").onClick(window.location.reload(false));
        // onClick={() => window.location.reload(false)}
        var lastScrollTop = 0;

        window.addEventListener("scroll", function(){
          

           var st = window.pageYOffset || document.documentElement.scrollTop; 
         
           if (st > lastScrollTop){
            $("#navbar").css("height", "0");
            $("#navbar").css("color", "#00000080");
           } else {
              console.log("upscroll")
              $("#navbar").css("height", "125px");
              $("#navbar").css("color", "whitesmoke");
           }
           lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }, false);
    }
render (){
    
    return(
        <div id="navbar">
        <div className="navLogoDiv">
        <Link to="/"><img id="navLogo" src="./assets/images/icons/adj-02.png" width="80px" height="50px" alt="adjective"/></Link>
        </div>
        

        <div className="homeMenu">
            {/* <span > <Link to="/" className="homeNav" id="homeId"> Home</Link> </span> */}
            {/* <span > <button className="homeNav" id="aboutId"> About</button> </span> */}
            <a className="homeNav" href="mailto: adjective.mediaco@gmail.com"><span>Contact</span></a>
            
        </div>

        <img id="menu" src="./assets/images/icons/burgermenu.png" alt="down" width="40px" height="20px"/>
       </div>
    )
}
}


export default Navbar;
