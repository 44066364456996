import React, { Component } from "react";
import './styles/frontcenter.css';
import $ from 'jquery';

class FrontCenter extends Component {
  componentDidMount(){
    $(".downDiv").click(function() {
      $('html,body').animate({
          scrollTop: $(".frontAboutContainer").offset().top},'slow');
    });
  }
render(){

  return(
    
  <div className="frontCenter">
    <div className="logoDiv">
      <img id="frontLogo" src="./assets/images/icons/adjectivemedia.png" alt="adjectivemedia"/>
    </div>
      
      <div className="downDiv">
      <div id="arrow-wrapper">
                      <div className="arrow-border">
                        <div className="arrow down"></div>
                        <div className='pulse'></div>
                      </div>
                    </div>
      </div>
      <div className="frontEdge"></div>
  </div>
  )
}
}


export default FrontCenter;

