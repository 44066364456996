import React from 'react';
import './styles/footer.css';

const Footer = () => (
<div className="footWrap">
<div class="footEdge">
    
    </div>
<div className="footOpt">
    <div className="footer">
    
        <div className="footLogoDiv">
            <img id="footLogo" src="./assets/images/icons/adjective-01.png" width="360px" height="64px" alt="adjectivemedia"/>
            <h2 className="logoBot">We <span id="tagline">Build</span> custom websites</h2>
            <span className="copy logoBot">Copyright &copy; 2021 Adjective Media Co.</span>
        </div>

        <div className="info">
                <h2 className="footHead lilmargin">Contact</h2>
                <hr className="footHr lilmargin"></hr>
                <div className="optDiv lilmargin">
                    <span className="opt">adjective.mediaco@gmail.com</span>
                    {/* <span className="opt">+1 (773) 629-9949</span> */}
                </div>
            </div>

        <div className="info">
                <h2 className="footHead lilmargin">Link with us</h2>
                
                <hr className="footHr lilmargin"></hr>
                <div className="socialDiv lilmargin">
                    <a target="_blank" href="https://www.instagram.com/the.adjective.media/"> <img className="socialIcon closetoo" src="./assets/images/icons/instaadj.png"></img></a>
                    <a target="_blank" href="https://www.facebook.com/adjective.media/"> <img className="socialIcon closetoo" src="./assets/images/icons/fbadj.png"></img></a>
                    <a target="_blank" href="https://www.linkedin.com/company/adjective-media/"><img className="socialIcon closetoo" src="./assets/images/icons/linkedadj.png"></img></a>
                </div>
            </div>

    </div>
 
    </div>
</div>

)

export default Footer;