import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Container from './styles/container.css';

// Components
import Navbar from './components/Navbar/Navbar.js';
import Modal from './components/Navbar/Modal.js';
import Footer from './components/Home/Footer.js'
// Pages
import Home from './pages/Home.js';
import Hbc from './pages/Hbc.js';
import Portfolio from './pages/Portfolio.js'
import About from './pages/About.js'
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop> 
      
      <div style={Container}>
        <Modal/>
        <Navbar />
        <Switch >
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/About" component={About} /> */}
        </Switch>
        <Footer/>
      </div>
      </ScrollToTop>
    </Router>
  )
}

export default App;
