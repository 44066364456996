import React, { Component } from 'react';
import './styles/Home.css';
import FrontCenter from '../components/Home/FrontCenter.js'
import FrontAbout from '../components/Home/FrontAbout.js'
import Process from '../components/Home/Process.js'
import Work from '../components/Home/Work.js'
import Badges from '../components/Home/Badges.js'
import Projects from '../components/Home/Projects.js'
import Email from '../components/Home/Email.js'
import Footer from '../components/Home/Footer.js'

import Carousel from '../components/Home/Carousel.js'

class Home extends Component {
    componentDidMount() {
        var something = (function() {
          var executed = false;
          return function() {
              if (!executed) {
                  executed = true;
                  // do something
                  (window.location.reload(false));
              }
          };
      })();

    }
    render(){
  
        return(
 
    <div>
        <FrontCenter/> 
        <FrontAbout />
        <Process />
        {/* <Work /> */}
        <Carousel />
        <Email />
    </div>
        )
    }
}

export default Home;