import React from 'react';
import './styles/email.css';

const Email = () => (
<div className="emailWrap">
<div className="mailHeader">
                <h2 className="talk">Ready to Talk?</h2>
                <div className="mailBtns">
                    <a id="contact" href="mailto: adjective.mediaco@gmail.com">
                    <button className="emailBtn">Start a project</button>
                    </a>
                    
                </div>
        </div>
</div>

)

export default Email;