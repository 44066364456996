 
import React, { Component } from "react";
import { Link } from 'react-router-dom'
import './styles/reviews.css';
import $ from 'jquery';



class Reviews extends Component {
    componentDidMount() {
        /* --------------------------------------------------
Slider Carousel
-------------------------------------------------- */

var $visibleSlide, getDataSlide, sliderInterval, getDataNextSlide, getDataPrevSlide, getDataNavDot, isAnimating = false;
var fadeDuration = 1000;
var pause = 5000;

//show first slide
$('.slides li:first-child').css('display', 'block');

//show first nav dot
$('.nav li:first-child').addClass('active-cd');

//find out what slide is visible and get its data attribute
function getSlideInfo() {
  $visibleSlide = $('.slides').find('li:visible');
  getDataSlide = $visibleSlide.data('n');
  getDataNextSlide = $visibleSlide.next().data('n');
  getDataPrevSlide = $visibleSlide.prev().data('n');
}

//show next slide
function showNextSlide() {
  getSlideInfo();

  if(isAnimating) return false;
  
  isAnimating = true;
  
  $('.nav li').removeClass('active-cd');

  if (getDataSlide < 3) {
    $visibleSlide.fadeOut(fadeDuration);
    $visibleSlide.next().fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li[data-cd=' + getDataNextSlide + ']').addClass('active-cd');
  } else {
    $visibleSlide.fadeOut(fadeDuration);
    $('.slides li:first-child').fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li:first-child').addClass('active-cd');
  }
} //end showNextSlide

function showPrevSlide() {
  getSlideInfo();

  if(isAnimating) return false;
  
  isAnimating = true;
  
  $('.nav li').removeClass('active-cd');

  if (getDataSlide > 1) {
    $visibleSlide.fadeOut(fadeDuration);
    $visibleSlide.prev().fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li[data-cd=' + getDataPrevSlide + ']').addClass('active-cd');
  } else {
    $visibleSlide.fadeOut(fadeDuration);
    $('.slides li:last-child').fadeIn(fadeDuration, function(){
      isAnimating = false;
    });
    $('.nav li:last-child').addClass('active-cd');
  }

} // end showPrevSlide

// controls
$('.next').on('click', showNextSlide);
$('.prev').on('click', showPrevSlide);

//autoplay
// function startSlider() {
//   sliderInterval = setInterval(showNextSlide, pause)
// }
// startSlider();
// $('.revSlideshow').mouseenter(function() {
//   clearInterval(sliderInterval);
// });
// $('.revSlideshow').mouseleave(startSlider);

//control dots clicks
// $('.nav li').on('click', function() {
//   getDataNavDot = $(this).data('cd');
//   getSlideInfo();

//   $('.nav li').removeClass('active-cd');
//   $(this).addClass('active-cd');

//   $visibleSlide.fadeOut(fadeDuration);
//   $('.slides li[data-n=' + getDataNavDot + ']').fadeIn(fadeDuration);
// }); //end dots click
          };
        

    
    
    render()
    {
        return(


<div className="rev revSlideshow">
    <h2 className="heresWhat">Here's what people say about us:</h2>
    <div className="bothArrows">
        <div className="prev arrow arrow-left"></div>
        <div className="next arrow arrow-right"></div>
  </div>

    <ul className="slides">
        <li data-n="1" className="reviewPiece">
            <h2>Erich Weber</h2>
            <p>"Adjective.media was very clear about answering all of our questions"</p>
        </li>

        <li data-n="2" className="reviewPiece">
            <h2>Lara David</h2>
            <p>"They communicated with me and delivered an elegant site on time and within budget"</p>

        </li>

        <li data-n="3" className="reviewPiece">
            <h2>Jones Black</h2>
            <p>"I can't thank adjective.media enough for creating a beautiful website!"</p>
        </li>
  </ul>

  <ul className="nav">
    <li data-cd="1"></li>
    <li data-cd="2"></li>
    <li data-cd="3"></li>
  </ul>
</div>
    )
}
}









export default Reviews;